import ReactSelect, { ActionMeta, OptionProps, SingleValue } from 'react-select'
import { IDistrict } from '../../../interfaces/district.interface'

export type OptionType = {
    value: number
    label: string
}

interface IProps {
    title: string
    name: string
    defaultValue: number
    districts: IDistrict[]
    onChange: (option: SingleValue<Pick<OptionType, 'value' | 'label'>>) => void
}

export const Select = (props: IProps) => {
    const options: OptionType[] = props.districts.map((district) => {
        return {
            value: district.id,
            label: district.title,
        }
    })

    return (
        <div className="form-group">
            <label className="form-label">{props.title}</label>
            <ReactSelect
                options={options}
                value={options.find((o) => o.value === props.defaultValue)}
                onChange={(option) => props.onChange(option)}
                name={props.name}
            />
        </div>
    )
}
