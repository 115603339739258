import React, { useCallback } from 'react'
import { Accept, useDropzone } from 'react-dropzone'

interface IProps {
    onDrop: (acceptedFiles: any) => any
    accept: Accept
}

export const Files = ({ onDrop, accept }: IProps) => {
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
        useDropzone({
            accept,
            onDrop,
            onError: (e) => console.log(e),
        })

    return (
        <div>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input className="input-zone" {...getInputProps()} />
                <div className="text-center">
                    {isDragActive ? (
                        <p className="dropzone-content">
                            Release to drop the files here
                        </p>
                    ) : (
                        <p className="dropzone-content">
                            Drag and drop some images here, or click to select
                            files
                        </p>
                    )}
                    <button type="button" className="btn">
                        Click to select files
                    </button>
                </div>
            </div>
        </div>
    )
}
