import { IFlat } from './../interfaces/flat.interface'
import axios from 'axios'

let host = ''
let baseURL = ''

if (process.env.NODE_ENV === 'development') {
    host = 'http://localhost:3000'
    baseURL = `${host}/cms`
} else if (process.env.NODE_ENV === 'production') {
    host = 'https://api.roommategeorgia.ge'
    baseURL = `${host}/cms`
}

const api = axios.create({
    baseURL,
    timeout: 1200000,
    headers: { Authorization: `Bearer ${localStorage.getItem('cms_token')}` },
})

api.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error?.response?.data?.statusCode === 401) {
            localStorage.removeItem('cms_token')
            window.location.href = '/login'
        } else {
            throw error
        }
    }
)

export const login = async (email: string, password: string) => {
    const url = `${baseURL}/auth/login`

    return axios.post(url, {
        email,
        password,
    })
}

export const getUsers = async (queryString = '') => {
    const url = `/users?${queryString}`

    return api.get(url)
}

export const getUserRequests = async (queryString = '') => {
    const url = `/user-requests?${queryString}`

    return api.get(url)
}

export const getUsersCount = async () => {
    const url = `/users/count`

    return api.get(url)
}

export const getUserRequestsCount = async () => {
    const url = `/user-requests/count`

    return api.get(url)
}

export const getCountedStats = (question: string) => {
    const url = `/user-answers/counted-stats?question=${question}`

    return api.get(url)
}

export const getStatsByMonths = () => {
    const url = `/users/stats-by-month`

    return api.get(url)
}

export const getStatsByDays = (month: string) => {
    const url = `/users/stats-by-day?month=${month}`

    return api.get(url)
}

export const getDistricts = () => {
    const url = `${host}/districts`

    return axios.get(url)
}

export const getFlats = (page: number) => {
    const url = `/flats?page=${page}`

    return api.get(url)
}

export const createFlat = async (
    body: IFlat
): Promise<{
    data: number
}> => {
    const url = `/flats`

    return api.post(url, body)
}

export const updateFlat = async (id: number, body: Partial<IFlat>) => {
    const url = `/flats/${id}`

    return api.patch(url, body)
}

export const getFlat = (id: number) => {
    const url = `/flats/view/${id}`

    return api.get(url)
}

export const deleteFlat = (id: number) => {
    const url = `/flats/${id}`

    return api.delete(url)
}

export const crawlPhones = (formData: FormData) => {
    const url = `/crawler/phones`

    return api.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // Important!
        },
    })
}
