import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { getStatsByMonths } from '../../../services'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const ByMonths = () => {
    const [stats, setStats] = useState([])

    useEffect(() => {
        getStatsByMonths().then((res) => {
            setStats(res.data)
        })
    }, [])

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Registered users by months in 2022 year',
            },
        },
    }

    const labels = stats.map((stat) => Object.keys(stat))

    const data = {
        labels,
        datasets: [
            {
                label: 'Users',
                data: stats.map((stat) => Object.values(stat)[0]),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    }

    return (
        <>
            <div className="col-md-3">
                <select className="form-control">
                    <option>2022</option>
                </select>
            </div>
            <Bar options={options} data={data} />
        </>
    )
}
