interface IProps {
    title: string
    name: string
    value: string
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

export const Textarea = (props: IProps) => {
    return (
        <div className="form-group">
            <label className="form-label">{props.title}</label>
            <textarea
                rows={5}
                name={props.name}
                onChange={props.onChange}
                value={props.value}
                className="form-control"
            ></textarea>
        </div>
    )
}
