import { Header } from './header'
import { Navigation } from './navigation'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useState } from 'react'

export const Layout = () => {
    let location = useLocation()
    const [sidebarClass, setSideBarClass] = useState<string>('app-sidebar')

    const toggleSideBar = () => {
        if (sidebarClass === 'app-sidebar') {
            setSideBarClass('app-sidebar ps ps--active-y open menu-open')
        } else {
            setSideBarClass('app-sidebar')
        }
    }

    if (!localStorage.getItem('cms_token')) {
        return <Navigate to="/login" state={{ from: location }} replace />
    } else {
        return (
            <>
                <div className="page">
                    <div className="page-main">
                        <Header toggleSideBar={toggleSideBar} />
                        <Navigation sideBarClass={sidebarClass} />
                        <div className="main-content app-content">
                            <div className="side-app">
                                <div className="main-container container-fluid mt-5">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
