import React, { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { login } from '../../../services'

export const Login = () => {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [error, setError] = useState<string>('')

    const onSubmitHandler = async (e: React.FormEvent) => {
        e.preventDefault()

        if (!username || !password || !username.includes('@')) {
            setError('All fields are required !')
            return false
        }

        try {
            const response = await login(username, password)
            localStorage.setItem('cms_token', response.data.access_token)
            window.location.href = '/dashboard'
        } catch (e) {
            setError('Incorrect credentials')
        }
    }

    let location = useLocation()

    if (localStorage.getItem('cms_token')) {
        return <Navigate to="/dashboard" state={{ from: location }} replace />
    } else {
        return (
            <>
                <div className="login-img">
                    <div className="page">
                        <div className="">
                            <div className="container-login100">
                                <div
                                    style={{
                                        minWidth: '400px',
                                    }}
                                    className="wrap-login100 p-6"
                                >
                                    <form
                                        className="login100-form validate-form"
                                        onSubmit={onSubmitHandler}
                                    >
                                        <span className="login100-form-title pb-5">
                                            Login
                                        </span>
                                        <div
                                            className={
                                                error.length
                                                    ? 'alert alert-danger'
                                                    : 'alert alert-danger d-none'
                                            }
                                        >
                                            <span>{error}</span>
                                        </div>
                                        <div className="panel panel-primary">
                                            <div className="panel-body tabs-menu-body p-0 pt-5">
                                                <div className="tab-content">
                                                    <div
                                                        className="tab-pane active"
                                                        id="tab5"
                                                    >
                                                        <div
                                                            className="wrap-input100 validate-input input-group"
                                                            data-bs-validate="Valid email is required: ex@abc.xyz"
                                                        >
                                                            <a className="input-group-text bg-white text-muted">
                                                                <i
                                                                    className="zmdi zmdi-email text-muted"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </a>
                                                            <input
                                                                className="input100 border-start-0 form-control ms-0"
                                                                type="email"
                                                                id="email"
                                                                onChange={(e) =>
                                                                    setUsername(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                placeholder="Email"
                                                            />
                                                        </div>
                                                        <div
                                                            className="wrap-input100 validate-input input-group"
                                                            id="Password-toggle"
                                                        >
                                                            <a className="input-group-text bg-white text-muted">
                                                                <i
                                                                    className="zmdi zmdi-eye text-muted"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </a>
                                                            <input
                                                                className="input100 border-start-0 form-control ms-0"
                                                                type="password"
                                                                onChange={(e) =>
                                                                    setPassword(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                id="password"
                                                                placeholder="Password"
                                                            />
                                                        </div>
                                                        <div className="container-login100-form-btn">
                                                            <button className="login100-form-btn btn-primary">
                                                                Login
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
  
}
