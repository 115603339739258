import { useEffect, useState } from 'react'
import { getFlats } from '../../../services'
import { ICreatedFlat } from '../../../interfaces/flat.interface'
import { FlatItem } from './flat-item'
import { Link } from 'react-router-dom'
import { Pagination } from './pagination'

type FlatsList = {
    data: ICreatedFlat[]
    meta: {
        page: number
        take: number
        itemCount: number
        pageCount: number
        hasPreviousPage: boolean
        hasNextPage: boolean
    }
}

export const FlatsList = () => {
    const [flats, setFlats] = useState<FlatsList>({
        data: [],
        meta: {
            page: 1,
            take: 1,
            itemCount: 1,
            pageCount: 1,
            hasPreviousPage: true,
            hasNextPage: true,
        },
    })

    const [page, setPage] = useState<number>(1)

    useEffect(() => {
        getFlats(page).then((res) => {
            setFlats(res.data)
        })
    }, [page])

    const renderFlats = () => {
        return flats.data.map((flat: ICreatedFlat) => (
            <FlatItem key={flat.id} flat={flat} />
        ))
    }

    const onClickPage = (action: number | string) => {
        if (action === 'prev') {
            setPage((prevState) => --prevState)
        } else if (action === 'next') {
            setPage((prevState) => ++prevState)
        } else {
            setPage(action as number)
        }
    }

    const renderedFlats = renderFlats()

    return (
        <div className="row">
            <div className="col-md-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Flats</h3>
                        <Link
                            className="btn btn-sm mx-4 btn-primary"
                            to="/flat-create"
                        >
                            Create Flat
                        </Link>
                    </div>
                    <div className="card-body">
                        <div className="">
                            <div className="row">{renderedFlats}</div>
                        </div>
                        <Pagination
                            hasNextPage={flats.meta.hasNextPage}
                            hasPreviousPage={flats.meta.hasPreviousPage}
                            pageCount={flats.meta.pageCount}
                            itemCount={flats.meta.itemCount}
                            take={flats.meta.take}
                            currentPage={page}
                            onClickPage={onClickPage}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
