import { ReactElement } from 'react'
import { IUserRequest } from '../../../interfaces/user-request.interface'

export const UserRequest = (props: IUserRequest) => {
    const iconByStatus = (status: number) => {
        let icon: ReactElement
        let classStatus = ''

        if (status === 2) {
            icon = (
                <i className="text-white side-menu__icon fe fe-check">
                    <h5>Accepted</h5>
                </i>
            )
            classStatus = 'success'
        } else if (status === 3) {
            icon = (
                <i className="text-danger side-menu__icon fe fe-x">
                    <h5>Rejected</h5>
                </i>
            )
            classStatus = 'danger'
        } else {
            icon = (
                <i className="text-white side-menu__icon fe fe-loader">
                    <h5>Pending</h5>
                </i>
            )
            classStatus = 'warning'
        }

        return <button className={`btn btn-${classStatus}`}>{icon}</button>
    }

    let receiver_profile_image = null
    let sender_profile_image = null

    if (props.sender_profile_image) {
        sender_profile_image = props.sender_profile_image
    } else {
        sender_profile_image =
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiB2WpFpR-0SD4furXgeDrU4GYYKTvoSy59iXsIxLnO7kfIhi6Ce-ttNPpT2cdj04oD_E&usqp=CAU'
    }

    if (props.receiver_profile_image) {
        receiver_profile_image = props.receiver_profile_image
    } else {
        receiver_profile_image =
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiB2WpFpR-0SD4furXgeDrU4GYYKTvoSy59iXsIxLnO7kfIhi6Ce-ttNPpT2cdj04oD_E&usqp=CAU'
    }

    return (
        <tr>
            <td className="text-center">{props.index}</td>
            <td className="align-middle">
                <div className="col-md-12 m-auto d-inline">
                    <div className="card text-wrap">
                        <div className="card-body text-center">
                            <img
                                src={sender_profile_image}
                                width="50"
                                height="50"
                                alt=""
                            />
                            <h6 className="mt-4 mb-2">
                                {props.sender_firstname} {props.sender_lastname}
                            </h6>
                        </div>
                    </div>
                </div>
            </td>

            <td>
                <div className="col-md-12 m-auto d-inline align-middle">
                    <div className="card text-wrap">
                        <div className="card-body text-center">
                            <img
                                src={receiver_profile_image}
                                width="50"
                                height="50"
                                alt=""
                            />
                            <h6 className="mt-4 mb-2">
                                {props.receiver_firstname}{' '}
                                {props.receiver_lastname}
                            </h6>
                        </div>
                    </div>
                </div>
            </td>
            <td className="text-center align-middle">
                {iconByStatus(props.status)}
            </td>
        </tr>
    )
}
