import { Link } from 'react-router-dom'
import { ICreatedFlat } from '../../../../interfaces/flat.interface'
import { deleteFlat } from '../../../../services'

interface FlatProp {
    flat: ICreatedFlat
}

export const FlatItem = ({ flat }: FlatProp) => {
    const onDelete = async (id: number) => {
        const answer = window.confirm('Are u sure ?')

        if (answer) {
            await deleteFlat(id)
            window.location.reload()
        }
    }

    return (
        <div className="col-md-3 col-xl-3">
            <div className="thumbnail">
                <a href={`/flat-edit/${flat.id}`}>
                    <img
                        src={flat.images.length ? flat.images[0].thumb : ''}
                        alt={flat.title}
                        className="thumbimg"
                    />
                </a>
                <div className="caption">
                    <h4>
                        <strong>{flat.title}</strong>
                    </h4>
                    <p
                        style={{
                            height: '60px',
                            overflow: 'hidden',
                        }}
                    >
                        {flat.description}
                    </p>
                    <span className="badge c-badge bg-success px-2 me-1 pb-1 mb-1">
                        {flat.district}
                    </span>
                    <span className="badge c-badge bg-success px-2 pb-1 me-1 mb-1">
                        {flat.price} ლ
                    </span>
                    <span className="badge c-badge bg-success px-2 pb-1 me-1 mb-1">
                        {flat.area} ²
                    </span>
                    <span className="badge c-badge bg-success px-2 pb-1 me-1 mb-1">
                        {flat.total_floors}/{flat.floor}
                    </span>
                    <hr />
                    <div>
                        <Link
                            to={`/flat-edit/${flat.id}`}
                            className="btn btn-primary btn-sm left"
                        >
                            <i
                                className="fe fe-edit"
                                data-bs-toggle="tooltip"
                                title=""
                                data-bs-original-title="fe fe-edit"
                                aria-label="fe fe-edit"
                            ></i>
                        </Link>

                        <button
                            className="btn btn-sm btn-danger ms-3"
                            onClick={() => onDelete(flat.id as number)}
                        >
                            <i
                                className="fe fe-trash"
                                data-bs-toggle="tooltip"
                                title=""
                                data-bs-original-title="fe fe-trash"
                                aria-label="fe fe-trash"
                            ></i>
                        </button>

                        <button className="btn btn-sm btn-primary ms-3">
                            <i className="fe fe-navigation"></i>
                            <span className="ms-1"> {flat.clicked}</span>
                        </button>

                        <button className="btn btn-sm btn-success ms-3">
                            <i className="fe fe-eye"></i>
                            <span className="ms-1">{flat.viewed}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
