import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Login } from './components/auth/login'
import { Dashboard } from './components/dashboard'
import { CreateFlat } from './components/flats/create'
import { EditFlat } from './components/flats/edit'
import { FlatsList } from './components/flats/list'
import { Layout } from './components/layout'
import { Statistics } from './components/statistics'
import { UserRequests } from './components/user-requests'
import { Users } from './components/users'
import { UserView } from './components/users/user-view'
import { CrawlPhones } from './components/crawl-phones'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />

                <Route element={<Layout />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/user-requests" element={<UserRequests />} />
                    <Route path="/statistics" element={<Statistics />} />
                    <Route path="/user-view" element={<UserView />} />
                    <Route path="/flats" element={<FlatsList />} />
                    <Route path="/flat-create" element={<CreateFlat />} />
                    <Route path="/flat-edit/:flatId" element={<EditFlat />} />
                    <Route path="/crawl-phones" element={<CrawlPhones />} />
                </Route>
            </Routes>
        </Router>
    </React.StrictMode>
)
