import { IFlat, IFlatLanguage } from '../../../interfaces/flat.interface'
import React, { useCallback, useEffect, useState } from 'react'
import { IDistrict } from '../../../interfaces/district.interface'
import { TextInput } from '../../forms/text-input'
import { createFlat, getDistricts, updateFlat } from '../../../services'
import { fields } from '../fields'
import { SingleValue } from 'react-select'
import { OptionType, Select } from '../../forms/select'
import { Checkbox } from '../../forms/checkbox'
import { NumberInput } from '../../forms/number-input'
import { Textarea } from '../../forms/textarea'
import { Files } from '../../forms/files'
import { ImageGrid } from '../../forms/files/image-grid'
import { useNavigate } from 'react-router-dom'

type FlatProps = {
    flat?: IFlat
}

type Value = string | number | boolean

const checkboxes = fields.filter((field) => field.type === 'checkbox')
const numberInputs = fields.filter((field) => field.type === 'number')

export const FlatForm = ({ flat }: FlatProps) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [errors, setErrors] = useState([])
    const languages = ['ka', 'en']
    const [districts, setDistricts] = useState<IDistrict[]>([])

    const navigate = useNavigate()

    const [flatProperties, setFlatProperties] = useState<IFlat>({
        district_id: 0,
        floor: 0,
        price: 0,
        area: 0,
        gas: false,
        bathroom: 0,
        capacity: 0,
        beds: 0,
        internet: false,
        hot_water: false,
        author: '',
        author_phone: '',
        street: '',
        working_space: false,
        bedroom: 0,
        balcony: 0,
        elevator: false,
        central_heating: false,
        heating: false,
        room: 0,
        images: [],
        languages: [
            {
                title: '',
                description: '',
                language: 'ka',
            },
            {
                title: '',
                description: '',
                language: 'en',
            },
        ],
        total_floors: 0,
    })

    useEffect(() => {
        if (flat && Object.keys(flat).length) {
            setFlatProperties(flat)
        }
    }, [flat])

    useEffect(() => {
        getDistricts().then((res) => setDistricts(res.data))
    }, [])

    const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target
        const newObj = { [name]: checked }

        if (name === 'central_heating' && checked) {
            newObj.heating = true
            newObj.hot_water = true
        }

        setFlatProperties((prevState) => {
            return {
                ...prevState,
                ...newObj,
            }
        })
    }

    const onChangeInput = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, type, value } = e.target

        updateProperties(name, value, type)
    }

    const onChangeSelect = (
        selected: SingleValue<Pick<OptionType, 'label' | 'value'>>
    ) => {
        if (selected) {
            updateProperties('district_id', selected.value, 'number')
        }
    }

    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.map((file: any) => {
            const reader = new FileReader()

            reader.onload = function (e: any) {
                const value = e.target.result
                setFlatProperties((prevState) => {
                    return {
                        ...prevState,
                        images: [...prevState.images, value],
                    }
                })
            }

            reader.readAsDataURL(file)
            return file
        })
    }, [])

    const onDeleteImage = (image: string) => {
        setFlatProperties((prevState) => {
            return {
                ...prevState,
                images: prevState.images.filter(
                    (item: any) => item.thumb !== image
                ),
            }
        })
    }

    const onSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()

        if (loading) {
            return false
        }

        setLoading(true)

        try {
            if (flat?.id) {
                await updateFlat(flat.id, flatProperties)
                window.location.reload()
            } else {
                const { data: id } = await createFlat(flatProperties)
                navigate(`/flat-edit/${id}`)
            }
        } catch (err: any) {
            setErrors(err.response.data.message)
            window.scrollTo(0, 0)
        } finally {
            setLoading(false)
        }
    }

    const updateProperties = (name: string, value: Value, type: string) => {
        if (name in flatProperties) {
            if (type === 'number' && value) {
                value = parseInt(value.toString())
            }

            const newObj = { [name]: value }

            setFlatProperties((prevState) => {
                return {
                    ...prevState,
                    ...newObj,
                }
            })
        } else {
            if (name.includes('language_ka') || name.includes('language_en')) {
                const split = name.split('_')
                name = split[2]
                const language = split[1]
                setFlatProperties((prevState) => {
                    const languages = prevState.languages.map((lang) => {
                        if (lang.language === language) {
                            lang[name as keyof IFlatLanguage] = value as string
                        }

                        return lang
                    })

                    return {
                        ...prevState,
                        languages,
                    }
                })
            }
        }
    }

    return (
        <form>
            <div className="col-md-12">
                {errors.length ? (
                    <div className="alert alert-danger">
                        {errors.map((error, index) => (
                            <p key={index}>{error}</p>
                        ))}
                    </div>
                ) : (
                    ''
                )}

                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Add new flat</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            {languages.map((language, index) => {
                                const flatLanguageProperties =
                                    flatProperties.languages.find(
                                        (fl) => fl.language === language
                                    )!
                                return (
                                    <div
                                        key={index}
                                        className="col-sm-6 col-md-6"
                                    >
                                        <TextInput
                                            onChange={onChangeInput}
                                            title={`Title [${flatLanguageProperties.language.toUpperCase()}]`}
                                            value={flatLanguageProperties.title}
                                            name={`language_${language}_title`}
                                        />
                                    </div>
                                )
                            })}
                            <div className="col-sm-6 col-md-6">
                                <Select
                                    onChange={onChangeSelect}
                                    title="District"
                                    defaultValue={flatProperties.district_id}
                                    districts={districts}
                                    name="district_id"
                                />
                            </div>
                            <div className="col-sm-6 col-md-6">
                                <TextInput
                                    onChange={onChangeInput}
                                    title={'Street'}
                                    value={flatProperties.street ?? ''}
                                    name={'street'}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextInput
                                    onChange={onChangeInput}
                                    title={'Author'}
                                    value={flatProperties.author ?? ''}
                                    name="author"
                                />
                            </div>
                            <div className="col-md-6">
                                <TextInput
                                    onChange={onChangeInput}
                                    title={'Author Phone'}
                                    value={flatProperties.author_phone ?? ''}
                                    name="author_phone"
                                />
                            </div>
                            {numberInputs.map((numberInput, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="col-sm-3 col-md-3"
                                    >
                                        <NumberInput
                                            onChange={onChangeInput}
                                            {...numberInput}
                                            value={
                                                flatProperties[
                                                    numberInput.name as keyof IFlat
                                                ] as number
                                            }
                                        />
                                    </div>
                                )
                            })}
                            <div className="row">
                                {checkboxes.map((checkbox, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="col-xl-2 col-md-2"
                                        >
                                            <Checkbox
                                                onChange={onChangeCheckbox}
                                                value={
                                                    flatProperties[
                                                        checkbox.name as keyof IFlat
                                                    ] as boolean
                                                }
                                                {...checkbox}
                                            />
                                        </div>
                                    )
                                })}
                            </div>

                            {languages.map((language, index) => {
                                const flatLanguageProperties =
                                    flatProperties.languages.find(
                                        (fl) => fl.language === language
                                    )!
                                return (
                                    <div
                                        key={index}
                                        className="col-sm-6 col-md-6"
                                    >
                                        <Textarea
                                            title={`Description [${flatLanguageProperties.language.toUpperCase()}]`}
                                            name={`language_${language}_description`}
                                            value={
                                                flatLanguageProperties.description
                                            }
                                            onChange={onChangeInput}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        <Files
                            onDrop={onDrop}
                            accept={{
                                'image/*': [],
                            }}
                        />
                        <ImageGrid
                            onDeleteImage={onDeleteImage}
                            images={flatProperties.images}
                        />
                        {/* <CustomGoogleMap /> */}
                        <button
                            type="button"
                            onClick={onSave}
                            disabled={loading}
                            className={
                                loading
                                    ? 'btn btn-success btn-loading'
                                    : 'btn btn-success'
                            }
                        >
                            {flat?.id ? 'Update' : 'Create'}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}
