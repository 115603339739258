import React from 'react'

type PaginationProps = {
    hasNextPage: boolean
    hasPreviousPage: boolean
    pageCount: number
    itemCount: number
    take: number
    currentPage: number
    onClickPage: (page: number | string) => void
}

export const Pagination = (props: PaginationProps) => {
    return (
        <div className="row">
            <div className="col-sm-12 col-md-12">
                <div
                    className="dataTables_info"
                    id="responsive-datatable_info"
                    role="status"
                    aria-live="polite"
                ></div>
            </div>
            <div className="col-sm-12 col-md-12">
                <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="responsive-datatable_paginate"
                >
                    <ul className="pagination">
                        <li
                            className={`paginate_button page-item previous ${
                                props.hasPreviousPage ? '' : 'disabled'
                            }`}
                            id="responsive-datatable_previous"
                        >
                            <a
                                href="#"
                                aria-controls="responsive-datatable"
                                data-dt-idx="0"
                                className="page-link"
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                    e.preventDefault()
                                    props.onClickPage('prev')
                                }}
                            >
                                Previous
                            </a>
                        </li>
                        {Array.from(
                            { length: props.pageCount },
                            (_, i) => i + 1
                        ).map((p, index) => (
                            <li
                                key={index}
                                className={`paginate_button page-item ${
                                    p === props.currentPage ? 'active' : ''
                                }`}
                            >
                                <a
                                    href="#"
                                    aria-controls="responsive-datatable"
                                    data-dt-idx="4"
                                    className="page-link"
                                    onClick={(
                                        e: React.MouseEvent<HTMLElement>
                                    ) => {
                                        e.preventDefault()
                                        props.onClickPage(p)
                                    }}
                                >
                                    {p}
                                </a>
                            </li>
                        ))}
                        <li
                            className={`paginate_button page-item next ${
                                props.hasNextPage ? '' : 'disabled'
                            }`}
                            id="responsive-datatable_next"
                        >
                            <a
                                href="#"
                                aria-controls="responsive-datatable"
                                data-dt-idx="6"
                                className="page-link"
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                    e.preventDefault()
                                    props.onClickPage('next')
                                }}
                            >
                                Next
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
