import { Link } from 'react-router-dom'
interface IProps {
    sideBarClass: string
}

export const Navigation = (props: IProps) => {
    return (
        <div className="sticky">
            <div
                className="app-sidebar__overlay"
                data-bs-toggle="sidebar"
            ></div>
            <div className={props.sideBarClass}>
                <div className="side-header">
                    <Link className="header-brand1" to="/dashboard">
                        <img
                            src="../assets/images/brand/logo.png"
                            className="header-brand-img desktop-logo"
                            alt="logo"
                        />
                        <img
                            src="../assets/images/brand/logo-1.png"
                            className="header-brand-img toggle-logo"
                            alt="logo"
                        />
                        <img
                            src="../assets/images/brand/logo-2.png"
                            className="header-brand-img light-logo"
                            alt="logo"
                        />
                        <img
                            src="../assets/images/brand/logo-3.png"
                            className="header-brand-img light-logo1"
                            alt="logo"
                        />
                    </Link>
                </div>

                <div className="main-sidemenu">
                    <ul className="side-menu">
                        <li className="sub-category">
                            <h3>Navigation</h3>
                        </li>

                        <Link className="side-menu__item" to="/dashboard">
                            <i className="side-menu__icon fe fe-airplay"></i>
                            <span className="side-menu__label">Dashboard</span>
                            <i className="angle fe fe-chevron-right"></i>
                        </Link>

                        <Link className="side-menu__item" to="/users">
                            <i className="side-menu__icon fe fe-user"></i>
                            <span className="side-menu__label">Users</span>
                            <i className="angle fe fe-chevron-right"></i>
                        </Link>

                        <Link className="side-menu__item" to="/user-requests">
                            <i className="side-menu__icon fe fe-users"></i>
                            <span className="side-menu__label">
                                User Requests
                            </span>
                            <i className="angle fe fe-chevron-right"></i>
                        </Link>

                        <Link className="side-menu__item" to="/statistics">
                            <i className="side-menu__icon fe fe-database"></i>
                            <span className="side-menu__label">Statistics</span>
                            <i className="angle fe fe-chevron-right"></i>
                        </Link>

                        <Link className="side-menu__item" to="/flats">
                            <i className="side-menu__icon fe fe-layers"></i>
                            <span className="side-menu__label">Flats</span>
                            <i className="angle fe fe-chevron-right"></i>
                        </Link>

                        <Link className="side-menu__item" to="/crawl-phones">
                            <i className="side-menu__icon fe fe-phone"></i>
                            <span className="side-menu__label">Crawl Phones</span>
                            <i className="angle fe fe-chevron-right"></i>
                        </Link>
                    </ul>
                </div>
            </div>
        </div>
    )
}
