export const fields = [
    {
        title: 'Central heating',
        name: 'central_heating',
        type: 'checkbox',
    },
    {
        title: 'Heating',
        name: 'heating',
        type: 'checkbox',
    },
    {
        title: 'Hot Water',
        name: 'hot_water',
        type: 'checkbox',
    },
    {
        title: 'Gas',
        name: 'gas',
        type: 'checkbox',
    },
    {
        title: 'Internet',
        name: 'internet',
        type: 'checkbox',
    },
    {
        title: 'Working Space',
        name: 'working_space',
        type: 'checkbox',
    },
    {
        title: 'Elevator',
        name: 'elevator',
        type: 'checkbox',
    },
    {
        title: 'Rooms',
        name: 'room',
        type: 'number',
    },
    {
        title: 'Bathrooms',
        name: 'bathroom',
        type: 'number',
    },
    {
        title: 'Price (GEL)',
        name: 'price',
        type: 'number',
    },
    {
        title: 'Area ²',
        name: 'area',
        type: 'number',
    },
    {
        title: 'Total Floors',
        name: 'total_floors',
        type: 'number',
    },
    {
        title: 'Floor',
        name: 'floor',
        type: 'number',
    },
    {
        title: 'Bedrooms',
        name: 'bedroom',
        type: 'number',
    },
    {
        title: 'Beds',
        name: 'beds',
        type: 'number',
    },
    {
        title: 'Balcony',
        name: 'balcony',
        type: 'number',
    },
    {
        title: 'Capacity',
        name: 'capacity',
        type: 'number',
    },
    {
        title: 'Street',
        name: 'street',
        type: 'text',
    },
    {
        title: 'Description',
        name: 'description',
        type: 'textarea',
    },
]
