import { Statistic } from './stats'
import { questionMappings } from './question-mappings'

export const Statistics = () => {
    return (
        <>
            <div className="row">
                {questionMappings.map((st) => (
                    <Statistic title={st.title} question={st.question} />
                ))}
            </div>
        </>
    )
}
