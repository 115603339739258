import { FlatForm } from '../form'
import { useEffect, useState } from 'react'
import { getFlat } from '../../../services'
import { useParams } from 'react-router-dom'
import { IFlat } from '../../../interfaces/flat.interface'

export const EditFlat = () => {
    const { flatId } = useParams()
    const [flat, setFlat] = useState<IFlat>({} as IFlat)

    useEffect(() => {
        getFlat(parseInt(flatId!)).then((res) => setFlat(res.data))
    }, [flatId])

    return (
        <div className="row flat-form">
            <FlatForm flat={flat} />
        </div>
    )
}
