import { IUser } from '../../../interfaces/user.interface'

export const User = (user: IUser) => {
    let profileImage = null
    let originalImage = ''

    if (user.profile_image) {
        profileImage = user.profile_image
        originalImage = user.profile_image.replace('/profile/', '/original/')
    } else {
        if (user.gender_id === 1) {
            profileImage =
                'https://negev-produce.com/wp-content/uploads/2022/01/NO_NEW1.jpg'
        } else {
            profileImage =
                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiB2WpFpR-0SD4furXgeDrU4GYYKTvoSy59iXsIxLnO7kfIhi6Ce-ttNPpT2cdj04oD_E&usqp=CAU'
        }
    }

    return (
        <div key={user.id} className="col-xl-3 col-sm-6 col-md-6">
            <div className="card border p-0">
                <div className="card-header text-center">
                    <h3 className="card-title">
                        {user.firstname} {user.lastname}
                    </h3>
                    <div className="gender m-auto">
                        <h4>
                            <i className="fa fa-" aria-hidden="true"></i>
                        </h4>
                    </div>
                    <div className="card-options">
                        <a
                            className="card-options-collapse"
                            data-bs-toggle="card-collapse"
                        >
                            <i className="fe fe-chevron-up"></i>
                        </a>
                        <a
                            className="card-options-remove"
                            data-bs-toggle="card-remove"
                        >
                            <i className="fe fe-x"></i>
                        </a>
                    </div>
                </div>
                <div className="card-body text-center">
                    <a href={originalImage} target="_blank">
                        <span
                            className="avatar avatar-xxl brround cover-image zoom"
                            style={{
                                backgroundImage: `url(${profileImage} )`,
                            }}
                        ></span>
                    </a>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">Phone: {user.phone}</li>
                        <li className="list-group-item">Age: {user.age}</li>
                        <li className="list-group-item">
                            Gender: {user.gender}
                        </li>
                        <li className="list-group-item">
                            Flat: {user.has_flat ? 'Yes' : 'No'}
                        </li>
                        <li className="list-group-item">
                            Sent requests: {user.sent_requests}
                            <br />
                            Received requests: {user.received_requests}
                        </li>
                    </ul>
                </div>
                <div className="card-footer d-flex">
                    <div className="row user-social-detail">
                        <div className="social-profile me-4 rounded text-center">
                            <a target="_blank" href={user.social_network}>
                                <i className="fa fa-facebook"></i>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="">
                            <button className='btn btn-secondary mb-3 btn-block'>
                                <a target="_blank" className='text-white' href={user.social_network}>
                                    <i className="fe fe-eye"></i>
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
