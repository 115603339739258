interface IProps {
    submitFormHandler: (e: React.FormEvent<HTMLFormElement>) => void
    filterOnChangeHandlder: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => void
    loading: boolean
}

export const Search = (props: IProps) => {
    const { submitFormHandler, filterOnChangeHandlder, loading } = props
    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Filters</h3>
                    </div>
                    <div className="card-body pb-2">
                        <form onSubmit={submitFormHandler}>
                            <div className="row row-sm">
                                <div className="col-lg-3">
                                    <label htmlFor="keyword">Keyword</label>
                                    <input
                                        onChange={filterOnChangeHandlder}
                                        type="text"
                                        id="keyword"
                                        name="keyword"
                                        className="form-control mb-4"
                                        placeholder="Keyword"
                                    />
                                </div>

                                <div className="col-lg-3">
                                    <label htmlFor="phone">Phone</label>
                                    <input
                                        onChange={filterOnChangeHandlder}
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        className="form-control mb-4"
                                        placeholder="Phone"
                                    />
                                </div>

                                <div className="col-lg-3">
                                    <label htmlFor="age_from">Age From</label>
                                    <input
                                        onChange={filterOnChangeHandlder}
                                        type="text"
                                        id="age_from"
                                        name="age_from"
                                        className="form-control mb-4"
                                        placeholder="Age From"
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="age_to">Age To</label>
                                    <input
                                        onChange={filterOnChangeHandlder}
                                        type="text"
                                        id="age_to"
                                        name="age_to"
                                        className="form-control mb-4"
                                        placeholder="Age To"
                                    />
                                </div>

                                <div className="col-lg-3">
                                    <label htmlFor="payed">Payed</label>
                                    <select
                                        onChange={filterOnChangeHandlder}
                                        name="payed"
                                        className="form-control"
                                        id="payed"
                                    >
                                        <option value="">Both</option>
                                        <option value="yes">Payed</option>
                                        <option value="no">Not payed</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="Gender">Gender</label>
                                    <select
                                        onChange={filterOnChangeHandlder}
                                        name="gender"
                                        className="form-control"
                                        id="gender"
                                    >
                                        <option value="">Both</option>
                                        <option value="1">Female</option>
                                        <option value="2">Male</option>
                                    </select>
                                </div>

                                <div className="col-lg-3">
                                    <label htmlFor="open_gender_to">
                                        Looking For a
                                    </label>
                                    <select
                                        onChange={filterOnChangeHandlder}
                                        name="open_gender_to"
                                        className="form-control"
                                        id="open_gender_to"
                                    >
                                        <option value="3">Both</option>
                                        <option value="1">Female</option>
                                        <option value="2">Male</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="available">Available</label>
                                    <select
                                        onChange={filterOnChangeHandlder}
                                        name="available"
                                        className="form-control"
                                        id="available"
                                    >
                                        <option value="3">Both</option>
                                        <option value="yes">Available</option>
                                        <option value="no">
                                            Not Available
                                        </option>
                                    </select>
                                </div>

                                <div className="col-lg-3 mt-4">
                                    <button
                                        className="btn btn-success"
                                        id="filter"
                                    >
                                        {loading ? 'Loading...' : 'Filter'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
