import { useSearchParams } from 'react-router-dom'

export const UserView = () => {
    const [searchparams] = useSearchParams()
    
    const userId = searchparams.get('user_id')

    return (
        <div className="row" id="user-profile">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="wideget-user mb-2">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="row">
                                        <div className="panel profile-cover">
                                            <div className="profile-cover__action bg-img"></div>
                                            <div className="profile-cover__img">
                                                <div className="profile-img-1">
                                                    <img
                                                        src="../assets/images/users/21.jpg"
                                                        alt="img"
                                                    />
                                                </div>
                                                <div className="profile-img-content text-dark text-start">
                                                    <div className="text-dark">
                                                        <h3 className="h3 mb-2">
                                                            Percy Kewshun
                                                        </h3>
                                                        <h5 className="text-muted">
                                                            Web Developer
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btn-profile">
                                                <button className="btn btn-primary mt-1 mb-1">
                                                    {' '}
                                                    <i className="fa fa-rss"></i>{' '}
                                                    <span>Follow</span>
                                                </button>
                                                <button className="btn btn-secondary mt-1 mb-1">
                                                    {' '}
                                                    <i className="fa fa-envelope"></i>{' '}
                                                    <span>Message</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="">
                                            <div className="social social-profile-buttons mt-5 float-end">
                                                <div className="mt-3">
                                                    <a
                                                        className="social-icon text-primary"
                                                        href=""
                                                    >
                                                        <i className="fa fa-facebook"></i>
                                                    </a>
                                                    <a
                                                        className="social-icon text-primary"
                                                        href=""
                                                    >
                                                        <i className="fa fa-twitter"></i>
                                                    </a>
                                                    <a
                                                        className="social-icon text-primary"
                                                        href=""
                                                    >
                                                        <i className="fa fa-youtube"></i>
                                                    </a>
                                                    <a
                                                        className="social-icon text-primary"
                                                        href=""
                                                    >
                                                        <i className="fa fa-rss"></i>
                                                    </a>
                                                    <a
                                                        className="social-icon text-primary"
                                                        href=""
                                                    >
                                                        <i className="fa fa-linkedin"></i>
                                                    </a>
                                                    <a
                                                        className="social-icon text-primary"
                                                        href=""
                                                    >
                                                        <i className="fa fa-google-plus"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="main-profile-contact-list">
                                    <div className="me-5">
                                        <div className="media mb-4">
                                            <div className="media-icon bg-secondary bradius me-3 mt-1">
                                                <i className="fe fe-edit fs-20 text-white"></i>
                                            </div>
                                            <div className="media-body">
                                                <span className="text-muted">
                                                    Posts
                                                </span>
                                                <div className="fw-semibold fs-25">
                                                    328
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="me-5 mt-5 mt-md-0">
                                        <div className="media mb-4">
                                            <div className="media-icon bg-danger bradius text-white me-3 mt-1">
                                                <span className="mt-3">
                                                    <i className="fe fe-users fs-20"></i>
                                                </span>
                                            </div>
                                            <div className="media-body">
                                                <span className="text-muted">
                                                    Followers
                                                </span>
                                                <div className="fw-semibold fs-25">
                                                    937k
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="me-0 mt-5 mt-md-0">
                                        <div className="media">
                                            <div className="media-icon bg-primary text-white bradius me-3 mt-1">
                                                <span className="mt-3">
                                                    <i className="fe fe-cast fs-20"></i>
                                                </span>
                                            </div>
                                            <div className="media-body">
                                                <span className="text-muted">
                                                    Following
                                                </span>
                                                <div className="fw-semibold fs-25">
                                                    2,876
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">About</div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <h5>
                                        Biography
                                        <i className="fe fe-edit-3 text-primary mx-2"></i>
                                    </h5>
                                    <p>
                                        Nor again is there anyone who loves or
                                        pursues or desires to obtain pain of
                                        itself, because it is pain, but because
                                        occasionally circumstances occur in
                                        which toil and pain can procure him some
                                        great pleasure.
                                        <a>Read more</a>
                                    </p>
                                </div>
                                <hr />
                                <div className="d-flex align-items-center mb-3 mt-3">
                                    <div className="me-4 text-center text-primary">
                                        <span>
                                            <i className="fe fe-briefcase fs-20"></i>
                                        </span>
                                    </div>
                                    <div>
                                        <strong>San Francisco, CA </strong>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-3 mt-3">
                                    <div className="me-4 text-center text-primary">
                                        <span>
                                            <i className="fe fe-map-pin fs-20"></i>
                                        </span>
                                    </div>
                                    <div>
                                        <strong>Francisco, USA</strong>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-3 mt-3">
                                    <div className="me-4 text-center text-primary">
                                        <span>
                                            <i className="fe fe-phone fs-20"></i>
                                        </span>
                                    </div>
                                    <div>
                                        <strong>+125 254 3562 </strong>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-3 mt-3">
                                    <div className="me-4 text-center text-primary">
                                        <span>
                                            <i className="fe fe-mail fs-20"></i>
                                        </span>
                                    </div>
                                    <div>
                                        <strong>georgeme@abc.com </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Skills</div>
                            </div>
                            <div className="card-body">
                                <div className="tags">
                                    <a className="tag">Laravel</a>
                                    <a className="tag">Angular</a>
                                    <a className="tag">HTML</a>
                                    <a className="tag">Vuejs</a>
                                    <a className="tag">Codiegniter</a>
                                    <a className="tag">JavaScript</a>
                                    <a className="tag">Bootstrap</a>
                                    <a className="tag">PHP</a>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    Work & Education
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="main-profile-contact-list">
                                    <div className="me-5">
                                        <div className="media mb-4">
                                            <div className="media-icon bg-primary  mb-3 mb-sm-0 me-3 mt-1">
                                                <svg viewBox="0 0 24 24">
                                                    <path
                                                        fill="#fff"
                                                        d="M12 3L1 9L5 11.18V17.18L12 21L19 17.18V11.18L21 10.09V17H23V9L12 3M18.82 9L12 12.72L5.18 9L12 5.28L18.82 9M17 16L12 18.72L7 16V12.27L12 15L17 12.27V16Z"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="media-body">
                                                <h6 className="font-weight-semibold mb-1">
                                                    Web Designer at{' '}
                                                    <a
                                                        href=""
                                                        className="btn-link"
                                                    >
                                                        Spruko
                                                    </a>
                                                </h6>
                                                <span>2018 - present</span>
                                                <p>Past Work: Spruko, Inc.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="me-5 mt-5 mt-md-0">
                                        <div className="media mb-4">
                                            <div className="media-icon bg-success text-white mb-3 mb-sm-0 me-3 mt-1">
                                                <svg viewBox="0 0 24 24">
                                                    <path
                                                        fill="currentColor"
                                                        d="M20,6C20.58,6 21.05,6.2 21.42,6.59C21.8,7 22,7.45 22,8V19C22,19.55 21.8,20 21.42,20.41C21.05,20.8 20.58,21 20,21H4C3.42,21 2.95,20.8 2.58,20.41C2.2,20 2,19.55 2,19V8C2,7.45 2.2,7 2.58,6.59C2.95,6.2 3.42,6 4,6H8V4C8,3.42 8.2,2.95 8.58,2.58C8.95,2.2 9.42,2 10,2H14C14.58,2 15.05,2.2 15.42,2.58C15.8,2.95 16,3.42 16,4V6H20M4,8V19H20V8H4M14,6V4H10V6H14M12,9A2.25,2.25 0 0,1 14.25,11.25C14.25,12.5 13.24,13.5 12,13.5A2.25,2.25 0 0,1 9.75,11.25C9.75,10 10.76,9 12,9M16.5,18H7.5V16.88C7.5,15.63 9.5,14.63 12,14.63C14.5,14.63 16.5,15.63 16.5,16.88V18Z"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="media-body">
                                                <h6 className="font-weight-semibold mb-1">
                                                    Studied at{' '}
                                                    <a
                                                        href=""
                                                        className="btn-link"
                                                    >
                                                        University
                                                    </a>
                                                </h6>
                                                <span>2004-2008</span>
                                                <p>
                                                    Graduation: Bachelor of
                                                    Science in Computer Science
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-body">
                                <form className="profile-edit">
                                    <textarea
                                        className="form-control"
                                        placeholder="What's in your mind right now"
                                        rows={7}
                                    ></textarea>
                                    <div className="profile-share border-top-0">
                                        <div className="mt-2">
                                            <a
                                                className="me-2"
                                                title="Audio"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                            >
                                                <span className="text-muted">
                                                    <i className="fe fe-mic"></i>
                                                </span>
                                            </a>
                                            <a
                                                className="me-2"
                                                title="Video"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                            >
                                                <span className="text-muted">
                                                    <i className="fe fe-video"></i>
                                                </span>
                                            </a>
                                            <a
                                                className="me-2"
                                                title="Image"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                            >
                                                <span className="text-muted">
                                                    <i className="fe fe-image"></i>
                                                </span>
                                            </a>
                                        </div>
                                        <button className="btn btn-sm btn-success ms-auto">
                                            <i className="fa fa-share ms-1"></i>{' '}
                                            Share
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card border p-0 shadow-none">
                            <div className="card-body">
                                <div className="d-flex">
                                    <div className="media mt-0">
                                        <div className="media-user me-2">
                                            <div className="">
                                                <img
                                                    alt=""
                                                    className="rounded-circle avatar avatar-md"
                                                    src="../assets/images/users/16.jpg"
                                                />
                                            </div>
                                        </div>
                                        <div className="media-body">
                                            <h6 className="mb-0 mt-1">
                                                Peter Hill
                                            </h6>
                                            <small className="text-muted">
                                                just now
                                            </small>
                                        </div>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="dropdown show">
                                            <a
                                                className="new option-dots"
                                                href=""
                                                data-bs-toggle="dropdown"
                                            >
                                                <span className="">
                                                    <i className="fe fe-more-vertical"></i>
                                                </span>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-end">
                                                <a className="dropdown-item">
                                                    Edit Post
                                                </a>
                                                <a className="dropdown-item">
                                                    Delete Post
                                                </a>
                                                <a className="dropdown-item">
                                                    Personal Settings
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <h4 className="fw-semibold mt-3">
                                        There is nothing more beautiful.
                                    </h4>
                                    <p className="mb-0">
                                        There are many variations of passages of
                                        Lorem Ipsum available, but the majority
                                        have suffered alteration in some form,
                                        by injected humour, or randomised words
                                        which don't look even slightly
                                        believable.
                                    </p>
                                </div>
                            </div>
                            <div className="card-footer user-pro-2">
                                <div className="media mt-0">
                                    <div className="media-user me-2">
                                        <div className="avatar-list avatar-list-stacked">
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround text-primary">
                                                +28
                                            </span>
                                        </div>
                                    </div>
                                    <div className="media-body">
                                        <h6 className="mb-0 mt-2 ms-2">
                                            28 people like your photo
                                        </h6>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="d-flex mt-1">
                                            <a
                                                className="new me-2 text-muted fs-16"
                                                href=""
                                            >
                                                <span className="">
                                                    <i className="fe fe-heart"></i>
                                                </span>
                                            </a>
                                            <a
                                                className="new me-2 text-muted fs-16"
                                                href=""
                                            >
                                                <span className="">
                                                    <i className="fe fe-message-square"></i>
                                                </span>
                                            </a>
                                            <a
                                                className="new text-muted fs-16"
                                                href=""
                                            >
                                                <span className="">
                                                    <i className="fe fe-share-2"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border p-0 shadow-none">
                            <div className="card-body">
                                <div className="d-flex">
                                    <div className="media mt-0">
                                        <div className="media-user me-2">
                                            <div className="">
                                                <img
                                                    alt=""
                                                    className="rounded-circle avatar avatar-md"
                                                    src="../assets/images/users/16.jpg"
                                                />
                                            </div>
                                        </div>
                                        <div className="media-body">
                                            <h6 className="mb-0 mt-1">
                                                Peter Hill
                                            </h6>
                                            <small className="text-muted">
                                                Sep 26 2019, 10:14am
                                            </small>
                                        </div>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="dropdown show">
                                            <a
                                                className="new option-dots"
                                                href=""
                                                data-bs-toggle="dropdown"
                                            >
                                                <span className="">
                                                    <i className="fe fe-more-vertical"></i>
                                                </span>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-end">
                                                <a className="dropdown-item">
                                                    Edit Post
                                                </a>
                                                <a className="dropdown-item">
                                                    Delete Post
                                                </a>
                                                <a className="dropdown-item">
                                                    Personal Settings
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="d-flex">
                                        <img
                                            src="../assets/images/media/22.jpg"
                                            alt="img"
                                            className="w-40 m-1 br-5"
                                        />
                                        <img
                                            src="../assets/images//media/24.jpg"
                                            alt="img"
                                            className="w-40 m-1 br-5"
                                        />
                                    </div>
                                    <h4 className="fw-semibold mt-3">
                                        There is nothing more beautiful.
                                    </h4>
                                    <p className="mb-0">
                                        There are many variations of passages of
                                        Lorem Ipsum available, but the majority
                                        have suffered alteration in some form,
                                        by injected humour, or randomised words
                                        which don't look even slightly
                                        believable.
                                    </p>
                                </div>
                            </div>
                            <div className="card-footer user-pro-2">
                                <div className="media mt-0">
                                    <div className="media-user me-2">
                                        <div className="avatar-list avatar-list-stacked">
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround text-primary">
                                                +28
                                            </span>
                                        </div>
                                    </div>
                                    <div className="media-body">
                                        <h6 className="mb-0 mt-2 ms-2">
                                            28 people like your photo
                                        </h6>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="d-flex mt-1">
                                            <a
                                                className="new me-2 text-muted fs-16"
                                                href=""
                                            >
                                                <span className="">
                                                    <i className="fe fe-heart"></i>
                                                </span>
                                            </a>
                                            <a
                                                className="new me-2 text-muted fs-16"
                                                href=""
                                            >
                                                <span className="">
                                                    <i className="fe fe-message-square"></i>
                                                </span>
                                            </a>
                                            <a
                                                className="new text-muted fs-16"
                                                href=""
                                            >
                                                <span className="">
                                                    <i className="fe fe-share-2"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border p-0 shadow-none">
                            <div className="card-body">
                                <div className="d-flex">
                                    <div className="media mt-0">
                                        <div className="media-user me-2">
                                            <div className="">
                                                <img
                                                    alt=""
                                                    className="rounded-circle avatar avatar-md"
                                                    src="../assets/images/users/16.jpg"
                                                />
                                            </div>
                                        </div>
                                        <div className="media-body">
                                            <h6 className="mb-0 mt-1">
                                                Peter Hill
                                            </h6>
                                            <small className="text-muted">
                                                Sep 24 2019, 09:14am
                                            </small>
                                        </div>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="dropdown show">
                                            <a
                                                className="new option-dots"
                                                href=""
                                                data-bs-toggle="dropdown"
                                            >
                                                <span className="">
                                                    <i className="fe fe-more-vertical"></i>
                                                </span>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-end">
                                                <a className="dropdown-item">
                                                    Edit Post
                                                </a>
                                                <a className="dropdown-item">
                                                    Delete Post
                                                </a>
                                                <a className="dropdown-item">
                                                    Personal Settings
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="d-flex">
                                        <img
                                            src="../assets/images/media/26.jpg"
                                            alt="img"
                                            className="w-30 m-1 br-5"
                                        />
                                        <img
                                            src="../assets/images/media/23.jpg"
                                            alt="img"
                                            className="w-30 m-1 br-5"
                                        />
                                        <img
                                            src="../assets/images/media/21.jpg"
                                            alt="img"
                                            className="w-30 m-1 br-5"
                                        />
                                    </div>
                                    <h4 className="fw-semibold mt-3">
                                        There is nothing more beautiful.
                                    </h4>
                                    <p className="mb-0">
                                        There are many variations of passages of
                                        Lorem Ipsum available, but the majority
                                        have suffered alteration in some form,
                                        by injected humour, or randomised words
                                        which don't look even slightly
                                        believable.
                                    </p>
                                </div>
                            </div>
                            <div className="card-footer user-pro-2">
                                <div className="media mt-0">
                                    <div className="media-user me-2">
                                        <div className="avatar-list avatar-list-stacked">
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround"></span>
                                            <span className="avatar brround text-primary">
                                                +28
                                            </span>
                                        </div>
                                    </div>
                                    <div className="media-body">
                                        <h6 className="mb-0 mt-2 ms-2">
                                            28 people like your photo
                                        </h6>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="d-flex mt-1">
                                            <a
                                                className="new me-2 text-muted fs-16"
                                                href=""
                                            >
                                                <span className="">
                                                    <i className="fe fe-heart"></i>
                                                </span>
                                            </a>
                                            <a
                                                className="new me-2 text-muted fs-16"
                                                href=""
                                            >
                                                <span className="">
                                                    <i className="fe fe-message-square"></i>
                                                </span>
                                            </a>
                                            <a
                                                className="new text-muted fs-16"
                                                href=""
                                            >
                                                <span className="">
                                                    <i className="fe fe-share-2"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Followers</div>
                            </div>
                            <div className="card-body">
                                <div className="">
                                    <div className="media overflow-visible">
                                        <img
                                            className="avatar brround avatar-md me-3"
                                            src="../assets/images/users/18.jpg"
                                            alt="avatar-img"
                                        />
                                        <div className="media-body valign-middle mt-2">
                                            <a
                                                href=""
                                                className=" fw-semibold text-dark"
                                            >
                                                John Paige
                                            </a>
                                            <p className="text-muted mb-0">
                                                johan@gmail.com
                                            </p>
                                        </div>
                                        <div className="media-body valign-middle text-end overflow-visible mt-2">
                                            <button
                                                className="btn btn-sm btn-primary"
                                                type="button"
                                            >
                                                Follow
                                            </button>
                                        </div>
                                    </div>
                                    <div className="media overflow-visible mt-sm-5">
                                        <span className="avatar cover-image avatar-md brround bg-pink me-3">
                                            LQ
                                        </span>
                                        <div className="media-body valign-middle mt-2">
                                            <a
                                                href=""
                                                className="fw-semibold text-dark"
                                            >
                                                Lillian Quinn
                                            </a>
                                            <p className="text-muted mb-0">
                                                lilliangore
                                            </p>
                                        </div>
                                        <div className="media-body valign-middle text-end overflow-visible mt-1">
                                            <button
                                                className="btn btn-sm btn-secondary"
                                                type="button"
                                            >
                                                Follow
                                            </button>
                                        </div>
                                    </div>
                                    <div className="media overflow-visible mt-sm-5">
                                        <img
                                            className="avatar brround avatar-md me-3"
                                            src="../assets/images/users/2.jpg"
                                            alt="avatar-img"
                                        />
                                        <div className="media-body valign-middle mt-2">
                                            <a
                                                href=""
                                                className="text-dark fw-semibold"
                                            >
                                                Harry Fisher
                                            </a>
                                            <p className="text-muted mb-0">
                                                harryuqt
                                            </p>
                                        </div>
                                        <div className="media-body valign-middle text-end overflow-visible mt-1">
                                            <button
                                                className="btn btn-sm btn-danger"
                                                type="button"
                                            >
                                                Follow
                                            </button>
                                        </div>
                                    </div>
                                    <div className="media overflow-visible mt-sm-5">
                                        <span className="avatar cover-image avatar-md brround me-3 bg-primary">
                                            IH
                                        </span>
                                        <div className="media-body valign-middle mt-2">
                                            <a
                                                href=""
                                                className="fw-semibold text-dark"
                                            >
                                                Irene Harris
                                            </a>
                                            <p className="text-muted mb-0">
                                                harris@gmail.com
                                            </p>
                                        </div>
                                        <div className="media-body valign-middle text-end overflow-visible mt-1">
                                            <button
                                                className="btn btn-sm btn-success"
                                                type="button"
                                            >
                                                Follow
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    Our Latest News
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="">
                                    <div className="media media-xs overflow-visible">
                                        <img
                                            className="avatar bradius avatar-xl me-3"
                                            src="../assets/images/users/12.jpg"
                                            alt="avatar-img"
                                        />
                                        <div className="media-body valign-middle">
                                            <a
                                                href=""
                                                className="fw-semibold text-dark"
                                            >
                                                John Paige
                                            </a>
                                            <p className="text-muted mb-0">
                                                There are many variations of
                                                passages of Lorem Ipsum
                                                available ...
                                            </p>
                                        </div>
                                    </div>
                                    <div className="media media-xs overflow-visible mt-5">
                                        <img
                                            className="avatar bradius avatar-xl me-3"
                                            src="../assets/images/users/2.jpg"
                                            alt="avatar-img"
                                        />
                                        <div className="media-body valign-middle">
                                            <a
                                                href=""
                                                className="fw-semibold text-dark"
                                            >
                                                Peter Hill
                                            </a>
                                            <p className="text-muted mb-0">
                                                There are many variations of
                                                passages of Lorem Ipsum
                                                available ...
                                            </p>
                                        </div>
                                    </div>
                                    <div className="media media-xs overflow-visible mt-5">
                                        <img
                                            className="avatar bradius avatar-xl me-3"
                                            src="../assets/images/users/9.jpg"
                                            alt="avatar-img"
                                        />
                                        <div className="media-body valign-middle">
                                            <a
                                                href=""
                                                className="fw-semibold text-dark"
                                            >
                                                Irene Harris
                                            </a>
                                            <p className="text-muted mb-0">
                                                There are many variations of
                                                passages of Lorem Ipsum
                                                available ...
                                            </p>
                                        </div>
                                    </div>
                                    <div className="media media-xs overflow-visible mt-5">
                                        <img
                                            className="avatar bradius avatar-xl me-3"
                                            src="../assets/images/users/4.jpg"
                                            alt="avatar-img"
                                        />
                                        <div className="media-body valign-middle">
                                            <a
                                                href=""
                                                className="fw-semibold text-dark"
                                            >
                                                Harry Fisher
                                            </a>
                                            <p className="text-muted mb-0">
                                                There are many variations of
                                                passages of Lorem Ipsum
                                                available ...
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Friends</div>
                            </div>
                            <div className="card-body">
                                <div className="user-pro-1">
                                    <div className="media media-xs overflow-visible">
                                        <img
                                            className="avatar brround avatar-md me-3"
                                            src="../assets/images/users/18.jpg"
                                            alt="avatar-img"
                                        />
                                        <div className="media-body valign-middle">
                                            <a
                                                href=""
                                                className=" fw-semibold text-dark"
                                            >
                                                John Paige
                                            </a>
                                            <p className="text-muted mb-0">
                                                Web Designer
                                            </p>
                                        </div>
                                        <div className="">
                                            <div className="social social-profile-buttons float-end">
                                                <a
                                                    className="social-icon bg-white"
                                                    href=""
                                                >
                                                    <i className="fa fa-facebook"></i>
                                                </a>
                                                <a
                                                    className="social-icon bg-white"
                                                    href=""
                                                >
                                                    <i className="fa fa-twitter"></i>
                                                </a>
                                                <a
                                                    className="social-icon bg-white"
                                                    href=""
                                                >
                                                    <i className="fa fa-linkedin"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="media media-xs overflow-visible mt-5">
                                        <span className="avatar cover-image avatar-md brround bg-pink me-3">
                                            LQ
                                        </span>
                                        <div className="media-body valign-middle mt-0">
                                            <a
                                                href=""
                                                className="fw-semibold text-dark"
                                            >
                                                Lillian Quinn
                                            </a>
                                            <p className="text-muted mb-0">
                                                Web Designer
                                            </p>
                                        </div>
                                        <div className="">
                                            <div className="social social-profile-buttons float-end">
                                                <a
                                                    className="social-icon bg-white"
                                                    href=""
                                                >
                                                    <i className="fa fa-facebook"></i>
                                                </a>
                                                <a
                                                    className="social-icon bg-white"
                                                    href=""
                                                >
                                                    <i className="fa fa-twitter"></i>
                                                </a>
                                                <a
                                                    className="social-icon bg-white"
                                                    href=""
                                                >
                                                    <i className="fa fa-linkedin"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="media media-xs overflow-visible mt-5">
                                        <img
                                            className="avatar brround avatar-md me-3"
                                            src="../assets/images/users/2.jpg"
                                            alt="avatar-img"
                                        />
                                        <div className="media-body valign-middle mt-0">
                                            <a
                                                href=""
                                                className="text-dark fw-semibold"
                                            >
                                                Harry Fisher
                                            </a>
                                            <p className="text-muted mb-0">
                                                Web Designer
                                            </p>
                                        </div>
                                        <div className="">
                                            <div className="social social-profile-buttons float-end">
                                                <a
                                                    className="social-icon bg-white"
                                                    href=""
                                                >
                                                    <i className="fa fa-facebook"></i>
                                                </a>
                                                <a
                                                    className="social-icon bg-white"
                                                    href=""
                                                >
                                                    <i className="fa fa-twitter"></i>
                                                </a>
                                                <a
                                                    className="social-icon bg-white"
                                                    href=""
                                                >
                                                    <i className="fa fa-linkedin"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="media media-xs overflow-visible mt-5">
                                        <span className="avatar cover-image avatar-md brround me-3 bg-primary">
                                            IH
                                        </span>
                                        <div className="media-body valign-middle mt-0">
                                            <a
                                                href=""
                                                className="fw-semibold text-dark"
                                            >
                                                Irene Harris
                                            </a>
                                            <p className="text-muted mb-0">
                                                Web Designer
                                            </p>
                                        </div>
                                        <div className="">
                                            <div className="social social-profile-buttons float-end">
                                                <a
                                                    className="social-icon bg-white"
                                                    href=""
                                                >
                                                    <i className="fa fa-facebook"></i>
                                                </a>
                                                <a
                                                    className="social-icon bg-white"
                                                    href=""
                                                >
                                                    <i className="fa fa-twitter"></i>
                                                </a>
                                                <a
                                                    className="social-icon bg-white"
                                                    href=""
                                                >
                                                    <i className="fa fa-linkedin"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
