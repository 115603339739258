interface IProps {
    title: string
    name: string
    value: boolean
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Checkbox = (props: IProps) => {
    return (
        <div className="form-group">
            <div className="form-label">{props.title}</div>
            <label className="custom-switch form-switch me-5">
                <input
                    onChange={props.onChange}
                    type="checkbox"
                    checked={props.value}
                    name={props.name}
                    className="custom-switch-input"
                />
                <span className="custom-switch-indicator custom-switch-indicator-md"></span>
            </label>
        </div>
    )
}
