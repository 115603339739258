import React, { useState } from 'react'
import { crawlPhones } from '../../services'

export const CrawlPhones = () => {
    const [url, setUrl] = useState<string>('')
    const [exceptedPhones, setExceptedPhones] = useState<string | Blob>('')
    const [pages, setPages] = useState<string>('1')
    const [loading, setLoading] = useState<boolean>(false)
    const [parsedPhones, setParsedPhones] = useState<Array<string>>([])
    const [eta, setEta] = useState<number>(1.9)
    const [listFormView, setListFormView] = useState(false)

    const onChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value)
    }

    const onChangePages = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? 1
        setPages(value)
        if (value) {
            setEta(Number((parseInt(value) * 1.9).toFixed(2)))
        }
    }

    const onFileChange = (e: React.ChangeEvent) => {
        const target = e.target as HTMLInputElement
        if (target.files && target.files.length > 0) {
            setExceptedPhones(target.files[0])
        }
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        if (!pages || !url) {
            alert('Pages and Url are required')

            return false
        }

        if (loading) return false

        setLoading(true)

        const formData = new FormData()
        formData.append('exceptedPhones', exceptedPhones)
        formData.append('url', url)
        formData.append('pages', pages)

        try {
            const response = await crawlPhones(formData)
            setParsedPhones(response.data)
            console.log('response data', response)
        } catch (exception) {
            console.log('exception', exception)
        } finally {
            setLoading(false)
        }
    }

    const drawPhonesList = () => {
        return (
            <div className="e-table px-5 pb-5">
                <div className="table-responsive table-lg">
                    <table className="table border-top table-bordered mb-0">
                        <thead>
                            <tr>
                                <th className="text-center">#</th>
                                <th className="">Phones</th>
                            </tr>
                        </thead>

                        {parsedPhones.length
                            ? parsedPhones.map((phone, index) => {
                                  return (
                                      <tbody key={index}>
                                          <tr>
                                              <td className="">{index + 1}</td>
                                              <td>{phone}</td>
                                          </tr>
                                      </tbody>
                                  )
                              })
                            : 'No phones yet'}
                    </table>
                </div>
            </div>
        )
    }

    const drawPhonesTextarea = () => {
        const phonesAsText = parsedPhones.length
            ? parsedPhones.join(';').replace(/ /g, '')
            : 'No phones yet'

        return (
            <textarea
                disabled
                value={phonesAsText}
                className="form-control"
            ></textarea>
        )
    }

    const selectView = () => {
        return <>{listFormView ? drawPhonesList() : drawPhonesTextarea()}</>
    }

    return (
        <>
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="row card-body pb-2 phones">
                        <form onSubmit={onSubmit} action="">
                            <div className="row">
                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <b>Url (ss.ge or myhome.ge)</b>
                                        </label>
                                        <input
                                            type="text"
                                            onChange={onChangeUrl}
                                            className="form-control"
                                            name="url"
                                            placeholder="ss.ge or myhome.ge url"
                                            value={url}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <label htmlFor="">
                                        <b>How many pages to parse?</b>
                                    </label>
                                    <div className="form-group">
                                        <input
                                            type="number"
                                            onChange={onChangePages}
                                            className="form-control"
                                            value={pages}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <div className="form-group">
                                        <label className="form-label">
                                            <b>
                                                Upload an excel of phones to
                                                subtract from the parsed phones
                                            </b>
                                        </label>
                                        <input
                                            onChange={onFileChange}
                                            className="form-control form-control-lg"
                                            type="file"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 col-md-2">
                                    <div className="form-group">
                                        <button className="btn btn-azure">
                                            {loading ? 'Loading...' : 'Run'}
                                        </button>
                                        <span style={{ marginLeft: '10px' }}>
                                            {'Eta ~'}
                                            {eta}m
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header border-bottom-0">
                        <button
                            onClick={() => setListFormView(false)}
                            className="btn btn-success"
                        >
                            Text View
                        </button>
                        <button
                            onClick={() => setListFormView(true)}
                            style={{ marginLeft: '10px' }}
                            className="btn btn-warning"
                        >
                            List View
                        </button>
                    </div>
                    <div className="card-body">
                        {<>{loading ? 'Loading...' : selectView()}</>}
                    </div>
                </div>
            </div>
        </>
    )
}
