import React, { useEffect, useState } from 'react'
import { IUser } from '../../interfaces/user.interface'
import { getUsers } from '../../services'
import { paramsToQueryString } from '../../utils'
import { Search } from './search'
import { User } from './user'

interface UsersResponse {
    list: IUser[]
    total: number
}

type IFilter = {
    [key in keyof IUser | any]: any
}

export const Users = () => {
    const [users, setUsers] = useState<UsersResponse>({
        list: [],
        total: 0,
    })
    const [queryString, setQueryString] = useState('')
    const [filters, setFilters] = useState<Partial<IFilter>>({})
    const [loading, setLoading] = useState<boolean>(false)

    const fetchUsers = () => {
        getUsers(queryString)
            .then((res) => {
                setUsers(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setLoading(true)
        fetchUsers()
    }, [queryString])

    const generateRows = () => {
        return users.list.map((user, index) => (
            <User key={index} {...{ ...{ index: index + 1 }, ...user }} />
        ))
    }

    const submitFormHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const qs = paramsToQueryString(filters)

        setQueryString(qs)
    }

    const filterOnChangeHandlder = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const newProperty = {
            [e.target.name]: e.target.value,
        }

        setFilters((prevState) => {
            return {
                ...prevState,
                ...newProperty,
            }
        })
    }

    return (
        <>
            <Search
                loading={loading}
                submitFormHandler={submitFormHandler}
                filterOnChangeHandlder={filterOnChangeHandlder}
            />
            <div className="row">{!loading && users && generateRows()}</div>
        </>
    )
}
