export const questionMappings = [
    {
        title: 'Gender',
        question: 'gender',
    },
    {
        title: 'Looking For a',
        question: 'looking_for_a',
    },
    {
        title: 'Flat',
        question: 'flat',
    },
    {
        title: 'Pet',
        question: 'pet',
    },
    {
        title: 'How long',
        question: 'how_long',
    },
    {
        title: 'Universities',
        question: 'university',
    },
    {
        title: 'Districts',
        question: 'district',
    },
    {
        title: 'Ready to pay',
        question: 'how_much_pay',
    },
]
