import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IStat } from '../../interfaces/stats.interface'
import { ByDays, ByMonths } from './charts'
import { getUserRequestsCount, getUsersCount } from '../../services'

export const Dashboard = () => {
    const [stats, setStats] = useState<IStat>({
        userRequests: 0,
        users: 0,
    })

    const fetchStats = async () => {
        const [users, userRequests] = await Promise.all([
            getUsersCount(),
            getUserRequestsCount(),
        ])

        const newState = {
            users: users.data,
            userRequests: userRequests.data,
        }

        setStats((prevState) => {
            return {
                ...prevState,
                ...newState,
            }
        })
    }

    useEffect(() => {
        fetchStats()
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                            <Link to="/users">
                                <div className="card overflow-hidden">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="mt-2">
                                                <h6 className="">
                                                    Total Users
                                                </h6>
                                                <h2 className="mb-0 number-font">
                                                    {stats.users}
                                                </h2>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="chart-wrapper mt-1">
                                                    <canvas
                                                        id="saleschart"
                                                        className="h-8 w-9 chart-dropshadow"
                                                    ></canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                            <Link to="/user-requests">
                                <div className="card overflow-hidden">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="mt-2">
                                                <h6 className="">
                                                    Total Requests
                                                </h6>
                                                <h2 className="mb-0 number-font">
                                                    {stats.userRequests}
                                                </h2>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="chart-wrapper mt-1">
                                                    <canvas
                                                        id="leadschart"
                                                        className="h-8 w-9 chart-dropshadow"
                                                    ></canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <ByMonths />
                </div>
                <div className="col-md-6">
                    <ByDays />
                </div>
            </div>
        </>
    )
}
