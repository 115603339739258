import React, { useEffect, useState } from 'react'
import { IUserRequest } from '../../interfaces/user-request.interface'
import { getUserRequests } from '../../services'
import { paramsToQueryString } from '../../utils'
import { UserRequest } from './user-request'

interface UserRequestsResponse {
    list: IUserRequest[]
    total: number
}

type IFilter = {
    [key in keyof IUserRequest | any]: any
}

export const UserRequests = () => {
    const [userRequests, setuserRequests] = useState<UserRequestsResponse>({
        list: [],
        total: 0,
    })
    const [queryString, setQueryString] = useState('')
    const [filters, setFilters] = useState<Partial<IFilter>>({})
    const [loading, setLoading] = useState<boolean>(false)

    const fetchUserRequests = () => {
        getUserRequests(queryString)
            .then((res) => {
                setuserRequests(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setLoading(true)
        fetchUserRequests()
    }, [queryString])

    const generateRows = () => {
        return userRequests.list.map((userRequest, index) => (
            <UserRequest
                key={userRequest.id}
                {...{ ...{ index: index + 1 }, ...userRequest }}
            />
        ))
    }

    const submitFormHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const qs = paramsToQueryString(filters)
        setQueryString(qs)
    }

    const filterOnChangeHandlder = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const newProperty = {
            [e.target.name]: e.target.value,
        }

        setFilters((prevState) => {
            return {
                ...prevState,
                ...newProperty,
            }
        })
    }

    return (
        <>
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Filters</h3>
                    </div>
                    <div className="card-body pb-2">
                        <form onSubmit={submitFormHandler}>
                            <div className="row row-sm">
                                <div className="col-lg-4">
                                    <label htmlFor="sender">Sender</label>
                                    <input
                                        onChange={filterOnChangeHandlder}
                                        type="text"
                                        id="sender"
                                        name="sender"
                                        className="form-control mb-4"
                                        placeholder="Sender Name or Lastname"
                                    />
                                </div>

                                <div className="col-lg-4">
                                    <label htmlFor="receiver">Receiver</label>
                                    <input
                                        onChange={filterOnChangeHandlder}
                                        type="text"
                                        id="receiver"
                                        name="receiver"
                                        className="form-control mb-4"
                                        placeholder="Sender Name or Lastname"
                                    />
                                </div>

                                <div className="col-lg-4">
                                    <label htmlFor="status">Status</label>
                                    <select
                                        onChange={filterOnChangeHandlder}
                                        name="status"
                                        className="form-select"
                                        id="status"
                                    >
                                        <option value="">All</option>
                                        <option value="1">Pending</option>
                                        <option value="2">Accepted</option>
                                        <option value="3">Rejected</option>
                                    </select>
                                </div>
                                <div className="col-lg-4 mt-4">
                                    <button
                                        className="btn btn-success"
                                        id="filter"
                                    >
                                        {loading ? 'Loading...' : 'Filter'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">
                            Requests ({userRequests.total})
                        </h3>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table
                                className="table table-bordered border text-nowrap mb-0 table-hover"
                                id="new-edit"
                            >
                                <thead>
                                    <tr>
                                        <th className="text-center align-middle">
                                            #
                                        </th>
                                        <th className="text-center">Sender</th>
                                        <th className="text-center">
                                            Receiver
                                        </th>
                                        <th className="text-center">Status</th>
                                    </tr>
                                </thead>
                                <tbody>{generateRows()}</tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
