import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { getStatsByDays } from '../../../services'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const ByDays = () => {
    const [stats, setStats] = useState([])
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    const d = new Date()

    let currentMonth = months[d.getMonth()]

    const [month, setMonth] = useState(currentMonth)

    useEffect(() => {
        const _monthIndex = (
            months.findIndex((m) => m === month) + 1
        ).toString()
        const monthIndex =
            _monthIndex.length === 1 ? `0${_monthIndex}` : _monthIndex

        getStatsByDays(monthIndex).then((res) => {
            setStats(res.data)
        })
    }, [month])

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: `Registered users by days in ${month}`,
            },
        },
    }

    const labels = stats.map((stat) => Object.keys(stat))

    const data = {
        labels,
        datasets: [
            {
                label: 'Users',
                data: stats.map((stat) => Object.values(stat)[0]),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    }

    const monthOnChangeHandler = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        setMonth(e.target.value)
    }

    return (
        <>
            <div className="col-md-3">
                <select
                    className="form-control"
                    defaultValue={currentMonth}
                    onChange={monthOnChangeHandler}
                >
                    {months.map((month) => (
                        <option key={month} value={month}>
                            {month}
                        </option>
                    ))}
                </select>
            </div>
            <Bar options={options} data={data} />
        </>
    )
}
