interface IProps {
    title: string
    name: string
    value: string | number
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
export const TextInput = (props: IProps) => {
    return (
        <div className="form-group">
            <label className="form-label">{props.title}</label>
            <input
                type="text"
                className="form-control"
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                placeholder={props.title}
            />
        </div>
    )
}
