import { useEffect, useState } from 'react'
import { getCountedStats } from '../../../services'

interface IAnswer {
    id: number
    title: string
    counted: string
}

interface IAnswerResponseInterface {
    stats: IAnswer[]
    total: number
}

interface IProp {
    title: string
    question: string
}

export const Statistic = (props: IProp) => {
    const [items, setItems] = useState<IAnswerResponseInterface>()
    const [closed, setClosed] = useState<boolean>(true)

    const onLoadClickHandler = () => {
        if (closed && !items) {
            getCountedStats(props.question).then((res) => {
                setItems(res.data)
            })
        }

        setClosed(!closed)
    }

    return (
        <div className="col-xl-3 col-lg-6 col-md-12">
            <div className="card">
                <div className="card-header d-block">
                    <h4
                        className="card-title fw-semibold"
                        style={{ float: 'left' }}
                    >
                        {props.title} - {items?.total}
                    </h4>
                    <button
                        className="btn btn-success btn-sm"
                        onClick={onLoadClickHandler}
                        style={{ float: 'right' }}
                    >
                        {closed ? (
                            <i
                                className="fe fe-chevrons-down"
                                data-bs-toggle="tooltip"
                                title=""
                                data-bs-original-title="fe fe-chevrons-down"
                                aria-label="fe fe-chevrons-down"
                            ></i>
                        ) : (
                            <i
                                className="fe fe-chevrons-up"
                                data-bs-toggle="tooltip"
                                title=""
                                data-bs-original-title="fe fe-chevrons-up"
                                aria-label="fe fe-chevrons-up"
                            ></i>
                        )}
                    </button>
                </div>
                {items &&
                    !closed &&
                    items.stats.length &&
                    items.stats.map((u) => {
                        const percent = (
                            (parseInt(u.counted) / items.total) *
                            100
                        ).toFixed(2)
                        return (
                            <div key={u.id} className="card-body">
                                <div className="browser-stats">
                                    <div className="row mb-4">
                                        <div className="col-sm-10 col-lg-9 col-xl-9 col-xxl-10 ps-sm-0">
                                            <div className="d-flex align-items-end justify-content-between mb-1">
                                                <h6 className="mb-1">
                                                    {u.title}
                                                </h6>
                                                <h6 className="fw-semibold mb-1">
                                                    {u.counted}
                                                    <span className="text-success fs-11">
                                                        ({percent}%)
                                                    </span>
                                                </h6>
                                            </div>
                                            <div className="progress h-2 mb-3">
                                                <div
                                                    className="progress-bar bg-primary"
                                                    style={{
                                                        width: `${percent}%`,
                                                    }}
                                                    role="progressbar"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}
