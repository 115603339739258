interface ImageItemProp {
    image: string
    onDeleteImage: (image: string) => void
}

interface ImageGridProp {
    images:
        | string[]
        | {
              original: string
              thumb: string
          }[]
    onDeleteImage: (image: string) => void
}

const ImageItem = ({ image, onDeleteImage }: ImageItemProp) => {
    return (
        <li
            className="col-xs-2 col-sm-2 col-md-2 col-xl-2 mb-5 border-bottom-0"
            data-responsive={image}
            data-src={image}
            data-sub-html={`img`}
            lg-event-uid="&amp;1"
        >
            <div className="card">
                <button
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        onDeleteImage(image)
                    }}
                    className="btn btn-sm btn-danger mb-1 image-delete-btn"
                    role="button"
                >
                    <i className="fa fa-trash"></i>
                </button>
                <img className="img-responsive br-5" src={image} alt={`img`} />
            </div>
        </li>
    )
}

export const ImageGrid = ({ images, onDeleteImage }: ImageGridProp) => {
    const renderImage = (image: any, index: number) => {
        return (
            <ImageItem
                onDeleteImage={onDeleteImage}
                image={image?.thumb ?? image}
                key={`${index + 1}-image`}
            />
        )
    }

    return (
        <div className="card custom-card">
            <div className="card-body pb-0">
                <h5 className="mb-3">Images</h5>
                <ul
                    id="lightgallery"
                    className="list-unstyled row"
                    lg-uid="lg0"
                >
                    {images.length ? images.map(renderImage) : 'Empty'}
                </ul>
            </div>
        </div>
    )
}
